.offers {

    .text-area {
      display: flex;
      justify-content: space-between;
    }
  
    .text-area div:last-child {
      text-align: center;
    }
  
    @media (max-width: 575px) {
      .text-area {
        display: flex;
        flex-direction: column;
      }
  
      .text-area div:last-child {
        text-align: left;
      }
  
      .center-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
    }
  
  }


  
  .secondary-badge{
    border: 1px solid #A7A7A7;
    width: fit-content;
    border-radius: 4px;
    padding: 6px 8px 6px 8px;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .offer-section {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
  }

  .height-correct{
    height: 178px !important;
    width: 93% !important;
  }
  
  .offer-card {
    background-color: #F6F6F6;
    height: 266px;
    width: auto;
    border-radius: 12px;
    display: flex;
    @media (max-width: 767px) {
      width: 90%;
      

    } 
  
    .locked-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      @media (max-width: 767px) {
      left:0px;

        width: 100%;
        
  
      } 
      width: 78%;
      bottom: 16px;
      right: 16px;
  
      .cursor-pointer {
        &:focus-visible {
          outline: 1px dashed #000;
          outline-offset: 2px;
        }
      }
  
      .unlock-wrap {
        display: flex;
        align-items: center;
  
        >span {
          display: inline-flex;
        }
      }
    }
  
    .offer-content {
      display: flex;
      flex-flow: column;
      position: relative;
    }
  
    img {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      object-fit: cover;
  
      @media (max-width: 767px) {
        height: 64px;
        width: 64px;
      }
    }
  
    .card-description {
      display: -webkit-box;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  .offers-signup{
    background-color: #DCF5E6 !important;
    height: 266px !important;
    width: 49.5% !important;
    padding: 20px 48px 20px 48px !important;
    display: flex;
    flex-direction: column;
align-items: center;
@media (max-width: 767px) {
  width: 100% !important;
  }  // width: 47%;
    // height:266px;
    // border-radius: 12px;
  }

  .image-bg{
    background: linear-gradient(0deg, rgba(246, 246, 246, 0.50) 0%, rgba(246, 246, 246, 0.50) 100%), linear-gradient(90deg, #FFF 24.72%, rgba(255, 255, 255, 0.00) 97.08%),url(https://s3-alpha-sig.figma.com/img/2bcc/fe58/ba42358b65e3d825e065334eac8b78fe?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Loj8Cy2Fa5mp5ncYk6I5zPqZUjKM0AlC04IQk04XVE99YDIbQvAP4VSYFGRoYzaIOkYqq6zkEvbN2v1mal-dMAnXj9a1tkGzVks5r66WpxpasyAeTq-qbwixHGgMR-kXAY~p7uQWcMt99Le1olQUDWHNmPp~YzCY80Nl4wUmF-ng0ScSKtIM1DuAvlhzxC7JP-4SPOPW9B0COrN3Idjws40dyVhgVV6xCFs2GCAsTYYFCxdkEjHODE0a-Kr3X6ttJaPVN8Z7qqJU-HQ0xnP4XIhWD84qMGZ~IWoSpCjWbo9-dOMwITyD9VfN76qpqfQt4hJjahNaB0mOplMXqGhx-w__), lightgray 273.519px -239.75px / 114.2% 228.281% no-repeat;
    background-blend-mode : screen, normal, normal;
}